import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../utility/utils"
import CTA from "../../../general/cta"

const TermsBusiness = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (
        <Fragment>
            <div className="kuda-inner--heading kuda-disclaimer--section">
                <div className="kuda-section--inner general-style">
                    <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
                        <Link to="/en-ng/legal/" className="color-black mb-3 mt-4 back-page">
                            <div className="align-self-center mr-2">
                                <div className="f-14">
                                    Legal
                                </div>
                            </div>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 mr-2 text-left">Nigeria</span>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 text-bold text-left">Terms and Conditions Kuda Business</span>
                        </Link>
                        <div className="legal-left">
                            <h1 className="kuda-section--heading mb-0 text-lg-biz text-xlbold color-primary title-bottom--spacing no-margins">
                                Terms and Conditions Kuda Business
                            </h1>
                        </div>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Background
                        </h1>
                        <p className="color-black bottom--spacing">
                            These terms and conditions will serve as a basis of your banking relationship with Kuda Microfinance Bank Limited (“Kuda” or the “Bank” ) and the Kuda Business Application (the “App” or “Application”). By choosing to open an account with us, you agree to the terms and conditions contained herein  You also agree to comply with the following policies which also apply to your use of our services and incorporated by reference into these terms and conditions:
                        </p>
                        <div className="px-3 mb-3">
                            <p className="color-black">
                                (a) Privacy Policy;
                            </p>
                            <p className="color-black mb-2">
                                (b) Cookies Policy.
                            </p>
                        </div>


                        <p className="color-black bottom--spacing">
                            If you do not agree to these terms and conditions, please do not proceed and exit the mobile application or the Kuda website immediately and do not proceed with the account opening steps. Also, please be informed that we reserve the right to terminate your relationship with us if we believe that you have violated any of these terms.
                        </p>

                    </div>


                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing mb-4">
                            Interpretation
                        </h1>
                        <p className="color-black bottom--spacing">
                            For ease of understanding, “You”, “your”, “Customer” means the person who seeks to enter into a banking relationship with Kuda and and agrees to these terms and conditions, while “We”, “us”, “our”,  shall mean Kuda, and following an assignment, any person or company to whom our rights herein are assigned to.
                        </p>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            About Us
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                Kuda Microfinance Bank Limited is a full service microfinance bank that is duly licensed by the Central Bank of Nigeria (“CBN”) and operating digitally.  Our deposits are also insured by the Nigeria Deposit Insurance Corporation (”NDIC”)
                            </p>
                        </div>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            The Objective Of Our Relationship With You
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                The objective of establishing a relationship with Kuda is to receive special services on offer by Kuda for its customers. Kuda will create and manage a bank account for the customer, as well as provide certain financial services including but not limited to savings, the granting of credit facilities, bill payments and any other service we are permitted by law to provide.
                            </p>
                            <p className="color-black bottom--spacing">
                                You can operate and manage your account via the Kuda Business App and the prerequisite for opening an account is a smartphone, with an active phone number, that meets our minimum technology requirements for operating system (iOS/Android) and supports the latest version of our Kuda Business App.  </p>
                        </div>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            When are these terms applicable?
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                These terms are applicable when you choose to open an account with Kuda and Kuda generates an account number for you.
                            </p>
                            <p className="color-black bottom--spacing">
                                We may, at any time, modify the terms and conditions of our relationship but we inform you of any modifications to these terms and conditions.  These modifications may be as a result of changes in our business practices or service offerings or may be in response to changes in the applicable laws that we are regulated by, or for any other lawful reason.
                            </p>
                            <p className="color-black bottom--spacing">
                                After we notify you of such updates or modifications to these terms and conditions, your continued use of our services will constitute an acceptance of the modified terms.  All updates will be detailed on our website and in the Kuda Business App. You will be able to access the latest version of our terms at any given time. If at any time the terms and conditions of these terms and conditions are no longer acceptable to you, you should immediately cease all use of the services provided by Kuda.
                            </p>

                        </div>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            What is the scope of the relationship between Kuda & the customer?
                        </h1>
                        <h4 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Opening an Account
                        </h4>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                You can access the financial services we provide, subject to your fulfillment of our on-boarding and Know-Your-Customer (KYC) checks in line with applicable laws.  For a start, you must be at least 18 years old, a Nigerian citizen, meet our technology requirements defined above and have not yet opened an account with us.
                            </p>
                            <p className="color-black bottom--spacing">
                                We do not knowingly engage with people who don’t meet these conditions.  Please note that these requirements may be updated or reviewed from time to time in line with applicable laws and our internal processes.
                            </p>
                            <p className="color-black bottom--spacing">
                                When you choose to open an account with us you agree to us using your information to make and receive payments on your behalf through your Kuda account. If you would like us to stop doing this, we would have to close your account.
                            </p>
                        </div>

                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Verifying your identity
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                We are required to apply due diligence measures aimed at the prevention of fraud, money laundering and terrorist financing before you can open an account with us. Among other measures, we are required to collect information and documents relating to the business, identify the persons behind the business, and verify their identities, as well as collect information and documents relating to the proposed use of the services by the Customer and the business.
                            </p>
                            <p className="color-black mb-2">
                                The Kuda Business App offers broadly two types of business accounts
                            </p>
                            <div className="mb-3 px-3 bottom--spacing">
                                <p className="color-black mb-2"> (1) Freelancer Accounts  and</p>
                                <p className="color-black mb-2">(2) registered / incorporated business Accounts. </p>
                            </div>
                            <div className="kuda-table bottom--spacing">
                                <table border="1">
                                    <thead>
                                        <tr>
                                            <th>Account Type</th>
                                            <th>Account Type Description</th>
                                            <th>Required Documentation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-bold"><p>Freelancer Accounts</p></td>
                                            <td>
                                                <p>These are accounts for individuals running very small scale businesses or freelancing and need business banking services that can help them setup quickly and hit the ground running.
                                                </p>
                                            </td>
                                            <td>
                                                <p>• Name <br />
                                                    • Phone Number <br />
                                                    • Selfie <br />
                                                    • BVN <br />
                                                    • NIN <br />
                                                    • Residential Address and proof of address.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-bold"><p>Full Business Accounts  - Business Name (BN)</p></td>
                                            <td>
                                                <p className="mb-3">These accounts are meant for CAC registered business names running semi-structured or structured operations and require extra business services that can cater to their business needs.</p>
                                                <p>
                                                    They are usually formed as either a sole proprietorship that is wholly owned by one person or a partnership between two or more people.</p>
                                            </td>

                                            <td>
                                                <div className="flex flex-column">
                                                    <div className="flex flex-column">
                                                        <p>
                                                            <span className="text-bold">Admin Information:</span>
                                                            Details of the person running the account on behalf of the business.
                                                        </p>
                                                        <p className="mt-3">
                                                            • BVN and a Valid ID <br />
                                                            •  Address and proof of address.
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-column">
                                                        <p className="text-bold">Business Information
                                                        </p>
                                                        <p className="mt-3">
                                                            • Your CAC registration number <br />
                                                            • Your FIRS or JTB Tax Identification Number (TIN) <br />
                                                            • Business Information and Ownership Details**** <br />
                                                            • Certificate of Registration of Business Name <br />
                                                            • CAC Registration Form - CAC BN/1 or CAC Status Report <br />
                                                            • Electronic Consent Form that all business owners must approve or a Letter of Authorisation for businesses that have more than one owner. <br />
                                                            • Proof of Address of your business
                                                        </p>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-bold"><p>Full Business Accounts  - Business name and Private Limited Liability Company (LLC)</p></td>
                                            <td>
                                                <p className="mb-3">
                                                    These accounts are meant for CAC registered companies running structured, scaled operations and require extra business services that can cater to their business needs.
                                                </p>
                                                <p>These businesses usually have an active Board of Directors and/or Shareholders.</p>
                                            </td>


                                            <td>
                                                <div className="flex flex-column">
                                                    <div className="flex flex-column">
                                                        <p>
                                                            <span className="text-bold">Admin Information:</span>
                                                            Details of the person running the account on behalf of the business.
                                                        </p>
                                                        <p className="mt-3">
                                                            • BVN and a Valid ID <br />
                                                            •  Address and proof of address.
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-column">
                                                        <p className="text-bold">Business Information
                                                        </p>
                                                        <p className="mt-3">
                                                            • Your CAC registration number <br />
                                                            • Your FIRS or JTB Tax Identification Number (TIN) <br />
                                                            • Business Information and Ownership Details <br />
                                                            • Certificate of Registration/Incorporation <br />
                                                            • Memorandum and Articles of Association <br />
                                                            • Application for Business Name Registration - CAC/BN 1or CAC Status Report<br />
                                                            • Particulars of Directors - CAC 7/7A or CAC 1.1 or CAC Status Report <br />
                                                            • Particulars of Shareholders - CAC 2/2A or CAC 1.1 or CAC Status Report<br />
                                                            • Electronic Consent Form that all Proprietors/Directors must approve instead or a traditional board resolution for company account opening.<br />
                                                            • Proof of address of your Company<br />
                                                            • SCUML or any other required License/Permit depending on your business type<br />
                                                            • BVN and Valid IDs for all Significant Shareholders (5% ownership) and Directors
                                                        </p>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Personal information
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black mb-2">
                                    In line with regulations, we perform due diligence on all accounts so it is your responsibility to ensure that all information and documents you provide to us are up-to-date on your profile at all times.

                                </p>
                                <p className="color-black mb-2">
                                    To enable us to comply with our Know Your Customer (“KYC”) and Anti-Money Laundering (“AML”) obligations, we may require you to provide any or all the following:
                                </p>
                                <div className="px-3 bottom--spacing">
                                    <p className="color-black mb-2">(a) your full name;</p>
                                    <p className="color-black mb-2">(b) your phone number,</p>
                                    <p className="color-black mb-2">(c) a valid email address;</p>
                                    <p className="color-black mb-2">(d) your date of birth;</p>
                                    <p className="color-black mb-2">(e) your Bank Verification Number (“BVN”);</p>
                                    <p className="color-black mb-2">(f) your National Identification Number (”NIN”);</p>
                                    <p className="color-black mb-2">(g) your current residential address;</p>
                                    <p className="color-black mb-2">(h) your picture;</p>
                                    <p className="color-black mb-2">(i) copy of a valid Government issued ID (national ID, international passport, permanent voter’s card or driver’s license);</p>
                                    <p className="color-black mb-2">(j) a copy of a recent utility bill or such other bills not older than three months of our request, which carries your name and the residential address you have provided; and</p>
                                    <p className="color-black mb-2">(k) any other information or document as we may require for our internal risk assessment.</p>

                                </div>
                                <p className="mb-3 color-black">You may also be required to provide us with one or more answers to security questions that will be used as part of this process. </p>
                                <p className="mb-3 color-black">You undertake that all information and documents which you present to us are true, accurate and up to date. If, at any point during the term of the Agreement, any of the information and documents presented to us should change or you identify that they were false, inaccurate and / or incorrect in any form, then you shall promptly present us with the respective updated information and documents.  </p>
                                <p className="mb-3 color-black">To verify the accuracy and authenticity of the information and documents you provide to us, we will need to share them them with third parties (including government agencies). You hereby agree and authorise Kuda to verify information provided by you against the information held by any third party (including official databases).</p>
                                <p className="mb-3 color-black">You also agree and authorise Kuda to obtain and procure your personal information and documents from relevant sources (private and official databases) and you further agree and consent to the disclosure and provision of such personal information by us or our third party partners.</p>
                                <p className="mb-3 color-black">You agree to indemnify and hold Kuda harmless with respect to any claims, losses, liabilities and expenses (including legal fees and expenses) that may arise as a result of the disclosure and reliance on such personal information provided by you.</p>
                                <p className="mb-3 color-black">We also may contact you to update your identification documents or information at any time and you are bound by these terms to continue to provide us with correct and up-to-date information to ensure the continuous usage of your Kuda account. We may also contact third parties to collect and / or verify the information and documents presented by you. We may limit /  restrict your usage of your Kuda Account and your access to funds held therein until such collection of information and / or verification is completed.</p>
                            </div>
                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Business information
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black mb-2">
                                    In our commitment to comply with Know Your Business (KYB) and Anti-Money Laundering (AML) regulations, it is imperative that you, as our business client, ensure that all business-related information and documents you provide are accurate, complete, and updated as necessary. During the onboarding process and periodically thereafter, you will be required to provide business information as part of our due diligence. The required documents and information may include, but are not limited to, the following:
                                </p>
                                <div className="flex flex-column mb-3">
                                    <p className="color-black mb-2">
                                        <span className="text-bold">Admin Information: </span>Details of the person managing the account on behalf of the business:
                                    </p>
                                    <p className="px-3 color-black">
                                        <ul className="diclaimer-list">
                                            <li>
                                                Your CAC registration number
                                            </li>
                                            <li>
                                                Proof of address (e.g., recent utility bills or bank statements).
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className="flex flex-column mb-3">
                                    <p className="color-black mb-2 text-bold">Business Information: </p>
                                    <p className="color-black bottom--spacing px-3">
                                        <ul className="diclaimer-list">
                                            <li>
                                                Corporate Affairs Commission (CAC) registration number.
                                            </li>
                                            <li>
                                                Federal Inland Revenue Service (FIRS) or Joint Tax Board (JTB) Tax Identification Number (TIN).
                                            </li>
                                            <li> Detailed business ownership information.
                                            </li>
                                            <li>Certificate of Incorporation for Limited Liability Companies.
                                            </li>
                                            <li> Certificate of Registration for Business Names. </li>
                                            <li> Memorandum and Articles of Association for companies.</li>
                                            <li> Application for Business Name Registration - CAC/BN 1or CAC Status Report</li>
                                            <li> Particulars of Directors (Form CAC 7/7A) and Shareholders (Form CAC 2/2A).</li>
                                            <li> Current CAC Status Report.</li>
                                            <li> An Electronic Consent Form completed by all Proprietors/Partners/Directors or owners, which must be updated as necessary.</li>
                                            <li> Proof of address of your Company</li>
                                            <li> SCUML or any other required License/Permit depending on your business type</li>
                                            <li> BVN and Valid IDs for all Significant Shareholders (5% ownership) and Directors</li>
                                        </ul>
                                    </p>
                                    <p className="mb-2 color-black">We reserve the right to verify the authenticity of the documents and information provided by sharing them with third parties, including government agencies. You agree to update your business information promptly in the event of any changes to maintain the integrity and accuracy of your business profile.</p>
                                    <p className="color-black mb-2">By providing the required documents and information, you authorize Kuda to verify these details against records held by third parties. You consent to the acquisition and retrieval of further information from relevant databases to complete your business profile.</p>
                                    <p className="color-black mb-2">You agree to indemnify and hold Kuda harmless against any claims, losses, liabilities, and expenses arising from any inaccuracies or the provision of false information.</p>
                                    <p className="color-black mb-2">Upon successful verification of provided information, Kuda will activate your business account and notify you via the app or email.</p>
                                    <p className="color-black mb-2">Continued access and use of your account are contingent upon adherence to these terms, including timely updates of your business information.</p>
                                    <p className="color-black mb-2">It is your responsibility to ensure that all provided documents and information are true and accurate. Failure to provide required documents or provision of false information may result in limitations or restrictions on your account access and functionality.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Main Business Account
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black mb-2">
                                    Upon completing our KYC, KYB and AML checks, we will open an account for you and issue you with a dedicated bank account number. Subject to the limits applicable to your Kuda Business Main Account, you can receive funds from third parties through bank transfers or fund the account yourself through various available means on the Kuda Business App.
                                </p>
                                <p className="color-black mb-3">
                                    You can also carry out several financial transactions from this account such as: instant outward bank transfers, bill (airtime, electricity etc.) payments, apply for and manage your Kuda Card, using this account, all subject to these terms and conditions.  Please note that this account is not an interest-yielding account.
                                </p>
                            </div>
                        </div>


                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Account limits
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    Transactions on your Kuda Business Account are subject to the following limits (which may be revised in line with applicable laws, regulatory directives and our internal processes):
                                </p>
                                <div className="kuda-table bottom--spacing">
                                    <table border="1">
                                        <thead>
                                            <tr>
                                                <th>Limit</th>
                                                <th>Freelancer</th>
                                                <th>Full Business</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Single Inflow Limit</p>
                                                </td>
                                                <td><p>Unlimited</p></td>
                                                <td><p>Unlimited</p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Cumulative Balance Limit</p>
                                                </td>
                                                <td><p>Unlimited</p></td>
                                                <td><p>Unlimited</p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Single Transfer Limit</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        ₦1,000,000 (Default) <br />
                                                        Up to ₦5,000,000 (with indemnity) <br />
                                                        Up to ₦25,000,000 (with EDD and compliance approval) <br />

                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        ₦5,000,000 (Default) <br />
                                                        Up to ₦25,000,000 (with indemnity) <br />
                                                        Up to ₦250,000,000 (with EDD and compliance approval)
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Daily Transfer Limit</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        ₦2,000,000 (Default)<br />
                                                        Up to ₦10,000,000 (with indemnity)<br />
                                                        Above ₦10,000,000 (with EDD and compliance approval)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        ₦ 25,000,000 (Default)<br />
                                                        Up to ₦100,000,000 (with indemnity)<br />
                                                        Up to ₦250,000,000 (with EDD and compliance approval)
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold"><p>Single Airtime Limit</p></td>
                                                <td><p>₦ 50,000</p></td>
                                                <td><p>₦ 50,000</p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Daily Airtime Limit</p>
                                                </td>
                                                <td><p>Unlimited</p></td>
                                                <td><p>Unlimited</p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Single Bills Payment (Betting)</p>
                                                </td>
                                                <td><p>₦ 50,000</p></td>
                                                <td><p>₦ 50,000</p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Daily Bills Payment (Betting)</p>
                                                </td>
                                                <td><p>₦ 5,000,000</p></td>
                                                <td><p>Unlimited</p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Single Bills Payment (Others - power, cable TV etc.)</p>
                                                </td>
                                                <td><p>As defined by service provider</p></td>
                                                <td><p>As defined by service provider</p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Daily Bills Payment (Others - power, cable TV etc.)</p>
                                                </td>
                                                <td><p>As defined by service provider</p></td>
                                                <td><p>As defined by service provider</p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Single Gift Cards Limit</p>
                                                </td>
                                                <td><p>As defined by service provider</p></td>
                                                <td><p>Not Applicable</p></td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    <p>Daily Gift Cards Limit</p>
                                                </td>
                                                <td><p>₦ 5,000,000</p></td>
                                                <td><p>Not Applicable</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="color-black mb-3">Transactions in excess of or in contravention of your account limits may be rejected or withheld in line with these terms and conditions.  In such a case, you will be required to upgrade your account to accommodate the transaction(s), as applicable.</p>
                            </div>
                        </div>

                        <div className="flex flex-column">

                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Kuda Card
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    On the Kuda Business App, you can apply for a Card and once you complete the process, we will issue you a Card that can be used at ATMs, POS Terminals, Merchant websites amongst others all based in Nigeria which accept the use of the applicable Card Scheme’s cards, to obtain cash and/or make payments for goods and services, as applicable.  You may also use your Kuda Card for contactless transactions, subject to the transaction limit set by you on your Kuda Business App, which you may change anytime subject to applicable laws.
                                </p>
                                <p className="color-black bottom--spacing">
                                    When we issue you a Kuda Card, you must use it for only lawful transactions and in accordance with our Card Issuance Terms and Conditions which you will have to accept at the point of applying for the Card.  When you use your Kuda Card for transactions on other payment channels which are not owned or controlled by the Bank, the rules of use of that channel apply in addition to our Card terms and conditions.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Transactions on your Kuda Account
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    You can conclude financial transactions (bank transfers, bill payments etc.) on the Kuda Business App by logging onto the Kuda Business App and following the on-screen instructions. You will need to enter the correct information with respect to every transaction you wish to undertake (counterparty bank account details, receiving payee for a bill information etc.).
                                </p>
                                <p className="color-black bottom--spacing">
                                    It is your responsibility to make sure that the details you input are accurate and entered correctly.  You will be liable for any error in information that may result in the transaction being unsuccessful or paid to the wrong beneficiary. We shall not be liable for any losses you incur from entering incorrect information with regard to any transaction you undertake.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Fees
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    We charge fees for certain services and these fees vary based on the services.  Our fees are charged in line with applicable laws and in line with our company ethos, we try to keep fees to a minimum. We are committed to being transparent and will disclose all applicable fees before you finalise a transaction.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Prohibited Transactions
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black mb-2">
                                    In addition to all transactions that are ordinarily prohibited by Nigerian law and activities not expressly permitted under these terms and conditions or any other terms we provide to you in connection with any services we provide, you are expressly prohibited from using your account to carry out transactions relating to any of the following:
                                </p>
                                <div className="bottom--spacing px-3">
                                    <p className="color-black mb-2">(a) fraud, money laundering and terrorism financing;</p>

                                    <p className="color-black mb-2">(b) activity related to the making or receiving of payments relating to cryptocurrency and related digital currency transactions;</p>

                                    <p className="color-black mb-2">(c) any goods or services that are dangerous, violent, or present an unreasonable risk to consumer safety (e.g., firearms, weapons, explosives, ammunition, and related accessories.)</p>

                                    <p className="color-black mb-2">(d) activities related to pornography, pornographic subscriptions, prostitution, pay-per view, adult live chat, escort or companionship services, or any other obscene materials (including literature, imagery, and other media); any product or service that promotes underage, non-consensual, or other illegal sexual themes. Activity involving child endangerment, including anything that sexualizes minors or appeals to children but contains adult themes;</p>

                                    <p className="color-black mb-2">(e) activities with elevated financial risk, legal liability, or violates card network rules or bank policies. Activity involving unlawful or illegal goods or services, including any federal or state law, statute, regulation, or ordinance. Activity related to bribes and corruption Activity that may be in violation of applicable laws. Activity relating to unlicensed and illegal sports betting, casino games and poker games; </p>

                                    <p className="color-black mb-2">(f) pyramid selling or Ponzi schemes, or other “get rich quick” schemes; and</p>

                                    <p className="color-black mb-2">(g) any activities we notify you are prohibited.</p>
                                </div>

                                <p className="color-black mb-2">
                                    If you use or attempt to use your account for any Prohibited Transactions or such other unlawful purposes, we reserve the right, without liability to:
                                </p>
                                <div className="mb-3 px-3">
                                    <p className="color-black mb-2">(i) - restrict your account;</p>

                                    <p className="color-black mb-2">(ii)- refuse to process the transaction;</p>

                                    <p className="color-black mb-2">(iii)- report the transaction to the relevant regulator or law enforcement agency; and</p>

                                    <p className="color-black mb-2">(iv)- take any other lawful step in accordance with our regulatory obligations.</p>

                                </div>

                            </div>
                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Keeping Your Account Safe
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    You must take all reasonable precautions to keep any security information relevant to your Kuda Account safe and to prevent the fraudulent use of them. You must use reasonable endeavors to prevent any unauthorised access to, or use of, your Kuda Account and should notify us immediately by email at
                                    <a
                                        href="mailto:help@kuda.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="color-black text-bold link-decoration cursor-pointer"
                                    >
                                        help@kuda.com
                                    </a>
                                    of any such unauthorised access or use, or any suspicion of access or use.
                                </p>
                                <p className="color-black bottom--spacing">
                                    Your login details for your Kuda Account should not be disclosed to anybody or kept in written form to prevent fraud and misuse of it.  We also recommend that obvious passwords, such as your name or the phase “password”, should not be used.
                                </p>

                                <p className="color-black mb-2">
                                    Additionally, You should also:
                                </p>
                                <div className="mb-3 px-3">
                                    <p className="color-black mb-2">(a) take care to ensure that no one hears or sees your login details when you use it;</p>

                                    <p className="color-black mb-2">(b) not disclose your full login details or password to anyone, including the police and us;</p>

                                    <p className="color-black mb-2">(c) never write your password down, nor keep a record of it;</p>

                                    <p className="color-black mb-2">(d) comply with all reasonable instructions we issue regarding keeping your login details and password;</p>

                                    <p className="color-black mb-2">(e) keep any computer / device you use to access the Kuda Business App secure and use up to date virus checking software and personal firewall software;</p>

                                    <p className="color-black mb-2">(f) never access the Kuda Business App from any shared device (or any public internet access device or access point) or any device connected to a local area network;</p>

                                    <p className="color-black mb-2">(g) make sure you always log out of the Kuda Business App, and never leaving your device unattended when you are logged in; and</p>

                                    <p className="color-black mb-2">(h) never share your Kuda Account details or payment details on social media or any website.</p>

                                </div>

                                <p className="color-black bottom--spacing">
                                    If your Account login details are lost or stolen, or if you suspect that someone else knows your login details or your verified mobile phone and / or e-mail are compromised, you must contact us by email at help@kuda.com without undue delay. Kuda shall not be liable for any loss you suffer as a result of any unauthorised transactions carried out in respect of your Kuda Account by a third party that obtains your log-in or other personal details, as a result of your negligence.
                                </p>

                            </div>
                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Restrictions on the use of Kuda’s Services
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    We may withhold, delay, freeze or place such restrictions as we consider appropriate on any or all funds representing proceeds of payments relating to your account and/or withhold any payments due to you under this Agreement, if, in our judgment you are, or may be engaged in fraudulent or suspicious activity, and/or there is a risk that you will unwilling or unable to meet your contractual obligations under this Agreement.
                                </p>
                                <p className="color-black mb-2">
                                    In addition, we shall be entitled to take any further action we reasonably consider necessary for security reasons in order to combat the risk of fraudulent or suspicious activity, including but not limited to:
                                </p>

                                <div className="mb-3 px-3">
                                    <p className="color-black mb-2">(a) suspending or terminating the provision of Services to you;</p>

                                    <p className="color-black mb-2">(b) refusing or rejecting instructions you provide in relation to the Services;</p>

                                    <p className="color-black mb-2">(c) suspending or stopping payments to you; and</p>

                                    <p className="color-black mb-2">(d) introducing additional authorisation procedures.</p>

                                </div>

                                <p className="color-black bottom--spacing">
                                    Provided we are permitted to do so under applicable law, we will give you notice of any suspension or restriction and the reasons for taking such action. If not permitted, we may notify you in writing of any action taken as soon as practicable after it has been taken, subject to applicable aw. Any suspension or restriction will be lifted as soon as reasonably practicable after the reasons for the action have ceased to exist.  Please note that your account may also be restricted for on-going KYB / KYC related due diligence checks such as, for example, a mismatch of the information or documents you provide to us part of on-going due diligence or KYB / KYC reviews.  In such a case, please follow the required steps to vacate such restrictions.
                                </p>

                            </div>
                        </div>

                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Customer Warranties
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black mb-2">
                                    You hereby represent and warrant that:
                                </p>
                                <div className="mb-3 px-3">
                                    <p className="color-black mb-2">(a)- you are using the Services for your personal benefit and are not accessing them for or on behalf of another person;</p>

                                    <p className="color-black mb-2">(b) - you have, and shall continue to have, the full right, power and authority to enter into and carry out your obligations under this agreement, and the provision of the Services will not result in a breach of any agreement to which you are bound; and </p>

                                    <p className="color-black mb-2">(c) - you shall keep the details of your Kuda Account safe and secure at all times from unlawful third party access and shall not compromise their security by sharing it over public channels, clicking of phishing or suspicious links or disclosing it to third parties. </p>

                                </div>


                            </div>
                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Monitoring Fraud, Anti Money Laundering and Countering Financing of Terrorism
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    To comply with relevant AML obligations, we may request information on the transactions carried out on your Account such as (a) details of the recipient (b) source of funds being transferred (c) purpose of the transfer or payment being made. You confirm that you will provide all requested information required in respect of the transaction in this regard.
                                </p>
                                <p className="color-black bottom--spacing">
                                    You also agree that we may cancel any transaction, suspend your Account or refuse to open an Account in your name if you refuse to provide the requested information or if any information provided is untrue or inaccurate.
                                </p>
                                <p className="color-black bottom--spacing">
                                    Please note that for certain transactions, we or our Third-Party Partners may be required to file AML or related reports (without notice to you) with the relevant regulators. These reports may include details of your personal information, transactions or other activities relating to your Account.
                                </p>

                            </div>
                        </div>
                        <div className="flex flex-column">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Liability
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    Except where you have acted fraudulently, or negligently or contrary to these terms and conditions you will not be liable for any losses incurred in respect of a transaction on your Account which was not authorised by you which occurs after you have notified us, without undue delay, on becoming aware of the loss, theft, misappropriation or unauthorised use of the Kuda Business App.
                                </p>
                                <p className="color-black bottom--spacing">
                                    You agree that we shall not be liable for the acts or omissions of any third party partner, nor its officers, employees or agents, in relation to their performance of any services under any third party terms.  We shall not be liable for non-execution or defective execution in relation to a transaction we have made in accordance with a unique identifier given to us by you which proves to be incorrect.
                                </p>
                                <p className="color-black bottom--spacing">
                                    We are not liable to you for the correct execution of an instruction, if we can prove to you (and where relevant, to any payee’s payment services provider) that the payee’s payment services provider received the payment within the appropriate time period. We will however, upon your request, make efforts to trace any non-executed or defectively executed payment transactions which were correctly executed to an account which is deemed fraudulent and notify you of any outcome involving our search.
                                </p>
                                <p className="color-black mb-2">
                                    We shall not be liable to you for any –
                                </p>

                                <div className="mb-3 px-3">
                                    <p className="color-black mb-2">(i) delay or failure to perform our obligations under this Agreement (including any delay in payment) by reason of any cause beyond our reasonable control including but not limited to any action or inaction by you or any third party, bank delay, postal delay, failure or delay of any fax or electronic transmission, any accident, emergency, act of god or any abnormal or unforeseeable circumstances; or </p>

                                    <p className="color-black mb-2">(ii) losses as a result of a requirement imposed on us by applicable law or  </p>

                                    <p className="color-black mb-2">(iii) any unauthorised transactions carried out on your Account as a result of the compromise of your Account details and related security information unless it is proven that such compromise is as a result of our direct actions or omissions. </p>
                                </div>

                                <p className="color-black mb-2">
                                    You are responsible for all liabilities, financial or otherwise, incurred by Kuda, a Kuda customer, or a third party caused by or arising out of your breach of these terms and conditions, your use of Kuda services, and any use of your Kuda Account. You agree to reimburse Kuda, a Kuda customer, or a third party for any and all such liability, to the extent not prohibited by applicable law
                                </p>
                                <p className="color-black bottom--spacing">
                                    You remain liable under these terms in respect of all charges and other amounts incurred through the use of your Kuda Account at any time, irrespective of termination, suspension or closure.
                                </p>
                            </div>
                        </div>
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            When things go wrong
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                To protect your money, we need you to keep your Card, phone and PIN safe at all times. If you lose your Card or spot any suspicious transactions on your account, please block your Card from the Kuda Business App and inform us as soon as possible. You are responsible for maintaining the security and confidentiality of your passwords, PINs (including one-time-passwords - OTPs) or any other identifier that you use to access your bank account and our services. Use a strong password and a unique code as your transaction PIN and limit its use to your Kuda account. Do not allow anyone to have or use your password or transaction PIN details. If you share these details, we cannot and will not be liable for any loss or damage arising from your failure to comply.
                            </p>
                        </div>

                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Closing an account
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                You can close your Kuda Account at any time and at no cost. If you’d like to do this, please get in touch with us. You will, however, remain liable for any obligations that are outstanding related to your Kuda Account.  These may include, any fees or charges that are due or if you have accessed any loans or an overdraft, the balance relating to the relevant credit facility.  Once the account is closed, you will not be able to access any Kuda services.
                            </p>

                            <p className="color-black mb-2">
                                We reserve the right to close, suspend, freeze or limit access to your account or institute an action against you, if:
                            </p>
                            <p className="color-black bottom--spacing px-3">
                                <ul className="diclaimer-list number-list">
                                    <li>
                                        The information we obtain from you does not comply with
                                        regulatory requirements;
                                    </li>
                                    <li>
                                        You do not meet, or are in breach of, the terms and
                                        conditions contained herein;
                                    </li>
                                    <li>You pose or create risk or possible legal exposure to us;</li>
                                    <li>We are required to do so by law;r</li>
                                    <li>
                                        There is a report of, or our investigations reveal that you have engaged in, fraudulent or suspicious activity with your Kuda account.
                                    </li>
                                </ul>
                            </p>
                            <p className="color-black mb-2">
                                Please note that the list above is not exhaustive.  If a fraudulent activity is associated with the operation of your account, you agree that we have the right to apply restrictions to your account and report to appropriate law enforcement agencies. Where possible, Kuda will provide you with the relevant information regarding the actions imposed on or taken in relation to your account, but we may be unable to do so in accordance with the appropriate law including avoiding disclosing protected third party information or interfering in the course of an investigation.
                            </p>
                            <p className="color-black bottom--spacing">
                                As a bank, we are required to comply with the international sanction laws and regulations issued by the relevant regulatory bodies as well as local laws and regulations applicable to banks, including those issued by the Central Bank of Nigeria. Therefore, we reserve the right to terminate your relationship with us by closing your Kuda Account without incurring any liability. We are also under no obligation to make any payment under, or otherwise to open this account if you violate any sanctions or there is involvement by any person (natural, corporate or governmental) listed in any international or local sanctions lists, or any involvement by or nexus with any sanctioned countries, or any of their governmental agencies.
                            </p>
                        </div>

                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Data Protection
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                Protecting your privacy is very important to us. Please review our Privacy Policy in order to better understand our commitment to maintaining your privacy and protecting your data, as well as our use and disclosure of your information.
                            </p>
                            <p className="color-black mb-2">
                                By opening this account, you consent to us processing your
                                personal information:
                            </p>
                            <p className="color-black bottom--spacing px-3">
                                <ul className="diclaimer-list">
                                    <li>
                                        to provide products and services to you in terms of this
                                        agreement and any other products and services for which you
                                        may apply;
                                    </li>
                                    <li>
                                        to carry out statistical and other analyses to identify
                                        potential markets and trends, evaluate and improve our
                                        business (this includes improving existing and developing
                                        new products and services);
                                    </li>
                                    <li>
                                        in countries outside Nigeria where the products or services
                                        are being used and where such countries do not have the same
                                        data protection laws as applicable in Nigeria, we will,
                                        where possible, ask the receiving party to agree to our
                                        privacy policies.
                                    </li>
                                </ul>
                            </p>


                            <p className="color-black bottom--spacing">
                                By opening this account, you also consent to us sharing your personal information with entities within the Kuda Group all in connection with the provision of financial services to you and related lawful purposes, as the need arises.
                            </p>
                        </div>

                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Marketing and Promotion
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                We may also use your personal or other information to share information with you on new products and services that may be of interest to you. By opening an account with us, you consent to us sending you updates about Kuda products. You can unsubscribe from our newsletters at any time using the opt-out link provided for this purpose in each newsletter we send.

                            </p>
                        </div>

                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Bank Verification Number (BVN) Operations and Watch-List for the
                            Nigerian Banking Industry
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black mb-2">By opening an account with us: </p>
                            <p className="color-black bottom--spacing">
                                <ul className="diclaimer-list px-3">
                                    <li>
                                        You consent to us applying restrictions on your account and reporting to law enforcement agencies, including the Economic and Financial Crimes Commission, the Nigerian Financial Intelligence Unit, the Nigerian Police Force, etc.., if a fraudulent activity is associated with the operation of your account; and
                                    </li>
                                    <li>
                                        You consent to us reporting to Nigeria Inter-Bank Settlement Systems Plc (NIBBS) for update on the Watchlist Database of the Nigerian Banking Industry and the CBN if a fraudulent activity is associated with the operation of your account.
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Notifications
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black mb-2">
                                By opening an account with us, you consent to receiving text (SMS), emails and push notifications from us. These notifications are meant for your consumption only and we will not be held liable if you suffer any loss, or damage as a result of unauthorised access to the information sent.
                            </p>
                            <p className="color-black mb-2">
                                In addition, by opening this account, you agree to opt out of mandatory SMS alerts directed by the CBN in favor of push notifications. You agree to indemnify Kuda against all losses, damages, claims, demands and expenses whatsoever which may be incurred, imposed or suffered by the Bank as well as against all actions, proceedings or claims (including attorney's fees) whether civil or criminal, which may be brought against Kuda by reason of such notifications.
                            </p>
                            <p className="color-black mb-2">
                                All notices / communications made in respect of these terms and conditions, and any provided under it by us, will be in English.
                            </p>
                            <p className="color-black mb-2">
                                We may provide you with notices/communications in connection with the services provided to you via:
                            </p>


                            <p className="color-black mb-2">
                                <div className="px-3  bottom--spacing">
                                    <p className="mb-2">
                                        (a) the Kuda Business App – when you login to the Kuda Business App;
                                    </p>
                                    <p className="mb-2">
                                        (b) e-mail – to the most recent e-mail address you provided to us.
                                    </p>
                                </div>
                            </p>

                            <p className="color-black mb-2">
                                You undertake that the contact details provided to us are up to date and that you can be reached using these contact details. If the contact details should change, then you shall without delay provide us with your updated contact details.
                            </p>
                            <p className="color-black mb-2">
                                Notices sent to you by us are deemed to have been received by you when:
                            </p>

                            <p className="color-black mb-2">
                                <div className="px-3 flex flex-column bottom--spacing">
                                    <p className="color-black mb-2">
                                        (a) sent by e-mail – on the Business Day it is sent;
                                    </p>
                                    <p className="color-black bottom--spacing">
                                        (b) e-mail – to the most recent e-mail address you provided to us.
                                    </p>
                                </div>
                            </p>
                        </div>

                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Intellectual Property
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                The Bank’s Website (and any other website operated by us) may feature third party offers and enable product searches. We do not warrant that product descriptions, pricing, search results, user ratings and reviews or any other content on any such website is accurate, complete, reliable, or current. This information is provided for informational purposes only and does not constitute an endorsement by us of any product, service, or vendor.
                            </p>
                        </div>

                        <p className="color-black bottom--spacing">
                            <a className="color-primary text-bold link-decoration cursor-pointer pr-2" href="https://kuda.com/">Kuda.com</a>,
                            <a target="_blank" rel="noopener noreferrer" className="color-primary text-bold link-decoration cursor-pointer" href="https://kudamfb.com/">Kudamfb.com</a>,
                            Kuda, Kuda Bank and all related logos, products, and services described on any other operated by Kuda (including the Website) and any mobile application operated by Kuda (including the App) are either trademarks or registered trademarks of Kuda Technologies Limited or Kuda Microfinance Bank Limited or its affiliates or licensors. You may not copy, imitate, or use them without our prior written consent. In addition, all page headers, custom graphics, button icons, and scripts are service marks, trademarks, and / or trade dress of Kuda UK. You may not copy, imitate, or use them without our prior written consent. All right, title, and interest in and to any website operated by Kuda and any content thereon is the exclusive property of Kuda and its licensors. Certain other product or service names, brand names and company names may be trademarks of their respective owners.
                        </p>

                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            General
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                <span className="text-xlbold">Additional Services: </span>From time to time, we may offer additional services through the Kuda Business App / Website with a view to improving the quality and type of Services we are currently able to provide you with. These additional services may be subject to separate terms and conditions which will be provided to you before you opt-in or a revision of these terms and conditions with notice to you. Where additional services include new fees, we will specify this to you and ensure that you first opt into any such additional fees.  If you choose to subscribe to an additional service, you agree to pay the relevant fees.
                            </p>

                            <p className="color-black bottom--spacing">
                                <span className="text-xlbold">Severability: </span>If any provision or part of a provision of these terms and conditions are found by any court of competent jurisdiction to be, invalid or unenforceable, such invalidity or unenforceability shall not affect the other provisions or parts of such provisions of this Agreement, all of which shall remain in full force and effect.
                            </p>

                            <p className="color-black bottom--spacing">
                                <span className="text-xlbold">Taxes:</span> Where applicable, certain payments made or received by you in connection with your Kuda Account or any Services provided to you by Kuda may be subject to taxes. If any taxes (whether in force now or introduced in the future) are payable in connection with these payments, you understand that you are liable for these taxes.      </p>

                            <p className="color-black bottom--spacing">
                                You hereby consent and agree that Kuda may withhold amounts in your Account if we are required to do so in accordance with the directives of any tax authority, regulator or by law or regulation. You will, however, be notified if such deductions are made or you will be able to see details of such deductions on your bank statements.
                            </p>

                            <p className="color-black bottom--spacing">
                                <span className="text-xlbold">Entire Agreement:</span> These terms and conditions constitute the entire agreement between You and Kuda and supersedes all previous agreements, representations and understandings between us, whether written or oral, relating to the provision of our services to you.  You acknowledge that in entering into this agreement you have not relied on any statement, representation, assurance or warranty (whether made innocently or negligently) that is not expressly set out herein.
                            </p>

                            <p className="color-black bottom--spacing">
                                Funds in your Kuda Account are covered under the Nigeria Deposit Insurance Scheme subject to the prevailing limits imposed by the Nigeria Insurance Deposit Corporation.
                            </p>
                        </div>



                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Language and communication
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                English will be the primary language of our communication to you. We may use emojis or colloquialisms, but we will always communicate in English.
                            </p>
                            <p className="color-black bottom--spacing">

                                If you have any questions about your relationship with Kuda, you can contact us via email using the details below or at any of our other channels (in app messages or our verified social media handles):


                                <ul className="diclaimer-list">
                                    <li>
                                        Email:{" "}
                                        <a
                                            href="mailto:help@kuda.com"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="color-primary text-bold link-decoration cursor-pointer"
                                        >
                                            help@kuda.com
                                        </a>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>


                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Applicable Law and Dispute Resolution
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                These terms and conditions will be governed by the laws of the Federal Republic of Nigeria and the courts of Nigeria will have exclusive jurisdiction over any claim, dispute or matter related thereto.   Notwithstanding the above, the Parties shall use their good faith efforts to resolve any dispute, controversy or claim of any nature whatsoever arising out of or in relation to or in connection with this agreement.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <CTA />
        </Fragment>
    )
}

export default TermsBusiness
